import React, { useState } from 'react'
import { Menu } from './Menu'
import { Header } from './Header'
import { Footer } from './Footer'
import { Loading } from './Loading'



import { URL_SVG } from '../utils/config'



export const ComoFunciona = () => {



	const [contadorLoading] = useState(0);




	return (
		<>
			<Menu seccion="comoFunciona" />
			<Header />
			<div className="container">
				<section className="bsb-timeline-2 py-5 py-xl-8">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-10 col-md-8 col-xl-6">
								<ul className="timeline">
									<li className="timeline-item">
										<span className="timeline-icon">
											<img src={URL_SVG + 'pelota.svg'} alt='Gol' className='h-100' />
										</span>
										<div className="timeline-body">
											<div className="timeline-content">
												<div className="card border-0">
													<div className="card-body p-0">
														<h5 className="card-subtitle text-secondary mb-1">Alta</h5>
														<h2 className="card-title mb-3">Alta del nuevo campeonato</h2>
														<p className="card-text m-0">
															Para comenzar da de alta tu nuevo campeonato con nombre único que será el que podrás compartir a los jugadores de todos los equipos así tienen todos los datos que necesitan tales como fechas, lugares de los partidos, tabla de posiciones, goleadores, etc.
														</p>
													</div>
												</div>
											</div>
										</div>
									</li>
									<li className="timeline-item">
										<span className="timeline-icon">
											<img src={URL_SVG + 'pelota.svg'} alt='Gol' className='h-100' />
										</span>
										<div className="timeline-body">
											<div className="timeline-content">
												<div className="card border-0">
													<div className="card-body p-0">
														<h5 className="card-subtitle text-secondary mb-1">Datos</h5>
														<h2 className="card-title mb-3">Datos de los integrantes</h2>
														<p className="card-text m-0">
															El setup de tu campeonato lo integran las sedes, los jueces, las categorías, los equipos y sus jugadores con la opción de cargar imágenes de cada uno. Este es el primer paso para administrar tu campeonato.
														</p>
													</div>
												</div>
											</div>
										</div>
									</li>
									<li className="timeline-item">
										<span className="timeline-icon">
											<img src={URL_SVG + 'pelota.svg'} alt='Gol' className='h-100' />
										</span>
										<div className="timeline-body">
											<div className="timeline-content">
												<div className="card border-0">
													<div className="card-body p-0">
														<h5 className="card-subtitle text-secondary mb-1">Torneos</h5>
														<h2 className="card-title mb-3">Generando Torneos</h2>
														<p className="card-text m-0">
															Si ya están los integrantes cargados, solo basta generar el fixture y los parámetros que tendrá cada torneo a disputar. Creas el torneo de la categoría correspondiente, seleccionas que equipos lo integran, los jugadores habilitados para el torneo y el sistema genera automáticamente el fixture. Se establecen los puntos por partido ganado, empatado o perdido  se da inicio al torneo. Todos los torneos generados estarán contenidos en el mismo campeonato para consultarlos en el momento que se necesite.
														</p>
													</div>
												</div>
											</div>
										</div>
									</li>

									<li className="timeline-item">
										<span className="timeline-icon">
											<img src={URL_SVG + 'pelota.svg'} alt='Gol' className='h-100' />
										</span>
										<div className="timeline-body">
											<div className="timeline-content">
												<div className="card border-0">
													<div className="card-body p-0">
														<h5 className="card-subtitle text-secondary mb-1">Partidos</h5>
														<h2 className="card-title mb-3">Jugando los partidos</h2>
														<p className="card-text m-0">
															Una vez que están todos los datos cargados y el torneo iniciado, ya se puede establecer que día y en que sede se disputará cada partido para el cual se imprime la lista de buena fe correspondiente a cada uno. Luego de cada uno se cargan los datos (goles, tarjetas amarillas, rojas) y de esta manera se va armando la tabla de posiciones, goleadoresa y demás datos del torneo.
															<br /><br />
															A jugar!!
														</p>
													</div>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Loading contador={contadorLoading} />
			<Footer />
		</>
	)
}
