import React from 'react';
//import { useLocation } from "react-router-dom";


//import { Helmet } from 'react-helmet';
// import logo from './logo.svg';
//import { Meta } from './utils/MetaUtils';

//import { getMeta } from './utils/consultas';
//import { LoadingProvider } from './utils/LoadingContext';
// import { DataProvider } from './components/DataContext'
//import { MiContextoProvider } from './utils/Contexto';



import { Home } from './components/Home';
//import { Menu } from './components/Menu';
//import { DataProvider } from './context/DataContext';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Buscador } from './components/Buscador';
import { Torneos } from './components/Torneos';
import { Tabla } from './components/Tabla';
import { Fixture } from './components/Fixture';
import { Goleadores } from './components/Goleadores';
import { Equipos } from './components/Equipos';
import { Equipo } from './components/Equipo';
import { Jugadores } from './components/Jugadores';
import { Jugador } from './components/Jugador';
import { Registro } from './components/Registro';
import { Partido } from './components/Partido';
import { ComoFunciona } from './components/ComoFunciona';


import ContextProvider from './components/Context';

//import './App.css';
// <DataProvider>


//import DynamicOgUpdater from './utils/DynamicOgUpdater';
// <BrowserRouter basename="/site"	>
function App() {

	return (
		<>
			<ContextProvider>
				<BrowserRouter basename='/site'>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/home" element={<Home />} />
						<Route path="/buscador" element={<Buscador />} />
						<Route path="/torneos/:nombreUrl" element={<Torneos />} />
						<Route path="/tabla/:idTorneo" element={<Tabla />} />
						<Route path="/fixture/:idTorneo" element={<Fixture />} />
						<Route path="/goleadores/:idTorneo" element={<Goleadores />} />
						<Route path="/equipos/:idTorneo" element={<Equipos />} />
						<Route path="/equipo/:idTorneo/:idEquipo" element={<Equipo />} />
						<Route path="/jugadores/:idTorneo/:idEquipo" element={<Jugadores />} />
						<Route path="/jugador/:idTorneo/:idJugador/:idEquipo" element={<Jugador />} />
						<Route path='/registro' element={<Registro />} />
						<Route path='/partido/:idPartido/:idTorneo' element={<Partido />} />
						<Route path='/comoFunciona' element={<ComoFunciona/>} />
					</Routes>
				</BrowserRouter>
			</ContextProvider>
		</>
	);
}



export default App;