import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



import { Loading } from './Loading';
import { Menu } from './Menu';
import { Header } from './Header';
import { API_URL, URL_IMAGES, URL_SVG } from '../utils/config';
import { Sidebar } from './Sidebar';
import { Compartir } from './Compartir';
import { Footer } from './Footer';



export const Jugadores = () => {



	// Global
	const [contadorLoading, setContadorLoading] = useState(0);
	const { idTorneo } = useParams();
	const { idEquipo } = useParams();


	let sidebar = { anular: "equipos", idTorneo };
	const navigate = useNavigate();
	//const { config } = useMiContexto();


	const [listaJugadores, setListaJugadores] = useState([]);
	const [listaEquipos, setListaEquipos] = useState([]);


	useEffect(() => {

		//document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

		if (listaEquipos.length === 0) {
			setContadorLoading(1);
			fetch(API_URL + 'Torneo/GetJugadores?IdEquipo=' + idEquipo + '&IdTorneo=' + idTorneo)
				.then((response) => {
					switch (response.status) {
						case 200: response.json().then((jugadores) => {
							setListaJugadores(jugadores);
							fetch(API_URL + 'Torneo/GetEquipos?IdTorneo=' + idTorneo)
								.then((response) => {
									switch (response.status) {
										case 200: response.json().then((equipos) => {
											setListaEquipos(equipos)

											//let equipo = equipos.filter(equipo => equipo.IdEquipo === idEquipo)[0];
											//document.querySelector('meta[property="og:title"]').setAttribute('content', 'Equipo: ' + equipo.Nombre);

										}); break;
										default: break;
									}
								})
								.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
								.finally(() => { setContadorLoading(-1) });
						}); break;
						default: break;
					}
				})
				.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
				.finally(() => { setContadorLoading(-1) });
		}
	}, [idEquipo, idTorneo, listaEquipos]);



	return (
		<>
			<Menu idTorneo={idTorneo} seccion="jugadores" />
			<Header idTorneo={idTorneo} />
			<div className="container">
				<div className="row">
					<main className="col-md-9">
						<section className="jugadores">
							<div className="panel-box panel-box-grey my-5">
								<div className="titles panel-box p-0">
									{
										listaEquipos.map(e => {
											if (e.IdEquipo === idEquipo) {
												return (<h4 className="titulo-listado-jugadores" key={0}>{e.Nombre}</h4>);
											} else {
												return false;
											}
										})
									}
								</div>
								<div className="row jugadores-list">
									{
										listaJugadores.map((j, index) => {
											return (
												<div className="col-md-6 col-lg-4" key={index}>
													<div className="panel-box" data-idjugador={j.IdJugador}>
														<div className="titles">
															<h4>{j.NombreJugador}</h4>
														</div>
														<div className="item px-2">
															<div className="item-head text-center">
																<img
																	src={j.ImagenPerfil === "" ? URL_SVG + "j0.svg" : URL_IMAGES + 'Jugador/' + j.ImagenPerfil}
																	alt={j.NombreJugador}
																	className="imgClass" />
															</div>
															<div className="item-footer mt-2">
																<div className="row">
																	<button
																		type="button"
																		className="btn btn-primary btn-get-jugador my-1"
																		onClick={() => { navigate('/jugador/' + idTorneo + '/' + j.IdJugador + '/' + idEquipo) }}
																		data-idjugador={j.IdJugador}>VER
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											)
										})
									}
								</div>
							</div>
						</section>
					</main>
					<aside className="col-md-3">
						<Sidebar data={sidebar} />
						<Compartir seccion="jugadores" idTorneo={idTorneo} idEquipo={idEquipo} />
					</aside>
				</div>
			</div>
			<Loading contador={contadorLoading} />
			<Footer/>
		</>
	)
}
