import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';




import { Loading } from './Loading';
import { Sidebar } from './Sidebar';
import { Compartir } from './Compartir';



import { API_URL } from '../utils/config'; // Importa la variable global
import { Header } from './Header';
import { Menu } from './Menu';
import { Footer } from './Footer';



export const Partido = () => {




	const [contadorLoading, setContadorLoading] = useState(0);



	const { idPartido } = useParams();
	const { idTorneo } = useParams();
	//const [idTorneo, setIdTorneo] = useState(0);
	const [datosPartido, setDatosPartido] = useState(null);
	const [listaDatos, setListaDatos] = useState(null);





	useEffect(() => {
		//document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
		setContadorLoading(1);
		fetch(API_URL + 'Torneo/GetPartidoDatos?IdPartido=' + idPartido)
			.then((response) => {
				switch (response.status) {
					case 200: response.json().then((partido) => {
						setDatosPartido(partido);
						//setIdTorneo(partido.IdTorneo);
						setListaDatos(JSON.parse(partido.Datos));
						/*
						document.querySelector('meta[property="og:title"]').setAttribute('content', 'Datos del partido');
						document.querySelector('meta[property="og:description"]').setAttribute('content', partido.NombreLocal + " vs " + partido.NombreVisitante );
*/
					}); break;
					default: break;
				}
			})
			.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
			.finally(() => { setContadorLoading(-1); });
	}, [idPartido])



	const sidebar = { actual: "partido", idTorneo };



	return (
		<>
			<Menu idTorneo={idTorneo} seccion="partido" />
			<Header idTorneo={idTorneo} />
			<div className="container">
				<div className="row">
					<main className="col-md-9">
						<section>
							<div className="panel-box my-5">
								<div className="titles">
									<h4>Partido</h4>
								</div>
								{
									datosPartido !== null ?
										<>
											<table className="table-striped table-hover">
												<tbody>
													{
														datosPartido.Jugado === 1 ?
															<tr>
																<td colSpan={2} className="text-truncate text-end "><span className='fw-bolder'>{datosPartido.NombreLocal} - {datosPartido.GolesLocal}</span></td>
																<td colSpan={2} className="text-truncate"><span className='fw-bolder'>{datosPartido.GolesVisitante} - {datosPartido.NombreVisitante}</span></td>
															</tr>
															:
															<tr>
																<td className="text-truncate fw-bolder h5">{datosPartido.NombreLocal}</td>
																<td className="text-truncate text-center">Fecha {datosPartido.Fecha}</td>
																<td className="text-truncate fw-bolder h5 text-end">{datosPartido.NombreVisitante}</td>
															</tr>
													}
													{
														datosPartido.Dia !== null &&
														<tr>
															<td></td>
															<td className="text-truncate fw-bolder text-center">{datosPartido.Dia}</td>
															<td></td>
														</tr>
													}
													{
														datosPartido.NombreSede !== "" &&
														<tr>
															<td></td>
															<td className="text-truncate fw-bolder text-center">{datosPartido.NombreSede}</td>
															<td></td>
														</tr>
													}
													{
														datosPartido.NombreJuez !== "" &&
														<tr>
															<td></td>
															<td className="text-truncate fw-bolder text-center">{datosPartido.NombreJuez}</td>
															<td></td>
														</tr>
													}
												</tbody>
											</table>
											{
												datosPartido.Jugado === 1 &&
												<div className="container-partido partido-jugado">
													<div className="partido-primer-tiempo"></div>
													<div className="partido-equipo partido-equipo-local">
														<h5 className="p-2">{datosPartido !== null ? datosPartido.NombreLocal : "Equipo Local"}</h5>
														{
															listaDatos !== null &&
															listaDatos.map((dato, index) => {
																return (
																	dato.IdEquipo === datosPartido.IdLocal ?
																		<div key={index}
																			title={dato.NombreJugador}
																			className={`dato ${dato.Dato === "G" ? ' gol ' : dato.Dato === "A" ? ' TA ' : ' TR '}`}
																			style={{ marginLeft: dato.Tiempo === 1 ? dato.Minuto * 45 / 100 + "%" : ((dato.Minuto * 45 / 100) + 50) + "%" }}>{dato.Minuto}</div>
																		:
																		null
																)
															})
														}
													</div>
													<div className="partido-equipo partido-equipo-visitante">
														{
															listaDatos !== null &&
															listaDatos.map((dato, index) => {
																return (
																	dato.IdEquipo === datosPartido.IdVisitante ?
																		<div key={index}
																			title={dato.NombreJugador}
																			className={`dato ${dato.Dato === "G" ? ' gol ' : dato.Dato === "A" ? ' TA ' : ' TR '}`}
																			style={{ marginLeft: dato.Tiempo === 1 ? dato.Minuto * 45 / 100 + "%" : ((dato.Minuto * 45 / 100) + 50) + "%" }}>{dato.Minuto}</div>
																		:
																		null
																)
															})
														}
														<h5 className="p-2">{datosPartido !== null ? datosPartido.NombreVisitante : "Equipo Visitante"}</h5>
													</div>
												</div>
											}
										</>
										:
										<div className="line"></div>
								}
							</div>
						</section>
					</main>
					<aside className="col-md-3">
						<Sidebar data={sidebar} />
						<Compartir seccion="partido" idPartido={idPartido} idTorneo={idTorneo} />
					</aside>
				</div>
			</div>
			<Loading contador={contadorLoading} />
			<Footer />
		</>
	)
}



/**
 






















									datosPartido !== null ?
								<>
									<div className="partido">
										{
											datosPartido !== null &&
											<>
												<table className="table-striped table-hover">
													<tbody>
														{
															datosPartido.Jugado === 1 ?
																<tr>
																	<td colSpan={2} className="text-truncate text-end "><span className='fw-bolder'>{datosPartido.NombreLocal} - {datosPartido.GolesLocal}</span></td>
																	<td colSpan={2} className="text-truncate"><span className='fw-bolder'>{datosPartido.GolesVisitante} - {datosPartido.NombreVisitante}</span></td>
																</tr>
																:
																<tr>
																	<td className="text-truncate fw-bolder h5">{datosPartido.NombreLocal}</td>
																	<td className="text-truncate text-center">Fecha {datosPartido.Fecha}</td>
																	<td className="text-truncate fw-bolder h5 text-end">{datosPartido.NombreVisitante}</td>
																</tr>
														}
														<tr>
															<td></td>
															<td className="text-truncate fw-bolder text-center">{datosPartido.Dia}</td>
															<td></td>
														</tr>
														<tr>
															<td></td>
															<td className="text-truncate fw-bolder text-center">{datosPartido.NombreSede}</td>
															<td></td>
														</tr>
														<tr>
															<td></td>
															<td className="text-truncate fw-bolder text-center">{datosPartido.NombreJuez}</td>
															<td></td>
														</tr>

													</tbody>
												</table>
											</>
										}
										{
											console.log(listaDatos)
										}
										{
											datosPartido.Jugado === 1 &&
											<div className="container-partido partido-jugado">
												<div className="partido-primer-tiempo"></div>
												<div className="partido-equipo partido-equipo-local">
													<h5 className="p-2">{datosPartido !== null ? datosPartido.NombreLocal : "Equipo Local"}</h5>
													{
														listaDatos !== null &&
														listaDatos.map((dato, index) => {
															return (
																dato.IdEquipo === datosPartido.IdLocal ?
																	<div key={index}
																		title={dato.NombreJugador}
																		className={`dato ${dato.Dato === "G" ? ' gol ' : dato.Dato === "A" ? ' TA ' : ' TR '}`}
																		style={{ marginLeft: dato.Tiempo === '1' ? Number(dato.Minuto) * 45 / 100 + "%" : ((Number(dato.Minuto) * 45 / 100) + 50) + "%" }}>{dato.Minuto}</div>
																	:
																	null
															)
														})
													}
												</div>
												<div className="partido-equipo partido-equipo-visitante">
													{
														listaDatos !== null &&
														listaDatos.map((dato, index) => {
															return (
																dato.IdEquipo === datosPartido.IdVisitante ?
																	<div key={index}
																		title={dato.NombreJugador}
																		className={`dato ${dato.Dato === "G" ? ' gol ' : dato.Dato === "A" ? ' TA ' : ' TR '}`}
																		style={{ marginLeft: dato.Tiempo === '1' ? Number(dato.Minuto) * 45 / 100 + "%" : ((Number(dato.Minuto) * 45 / 100) + 50) + "%" }}>{dato.Minuto}</div>
																	:
																	null
															)
														})
													}
													<h5 className="p-2">{datosPartido !== null ? datosPartido.NombreVisitante : "Equipo Visitante"}</h5>
												</div>
											</div>
										}
									</div>
									<div className="partido-sm">
										<table className="table-striped">
											<tbody>
												{
													datosPartido.Jugado === 1 ?
														<tr>
															<td className="text-truncate text-end "><span className='fw-bolder'>{datosPartido.NombreLocal} - {datosPartido.GolesLocal}</span></td>
															<td className="text-truncate"><span className='fw-bolder'>{datosPartido.GolesVisitante} - {datosPartido.NombreVisitante}</span></td>
														</tr>
														:
														<tr>
															<td className="text-truncate fw-bolder">{datosPartido.NombreLocal}</td>
															<td className="text-truncate fw-bolder text-end">{datosPartido.NombreVisitante}</td>
														</tr>
												}
												<tr>
													<td colSpan={2} className="text-truncate fw-bolder text-center">{datosPartido.Dia}</td>
												</tr>
												<tr>
													<td colSpan={2} className="text-truncate fw-bolder text-center">{datosPartido.NombreSede}</td>
												</tr>
												<tr>
													<td colSpan={2} className="text-truncate fw-bolder text-center">{datosPartido.NombreJuez}</td>
												</tr>
											</tbody>
										</table>
										<table>
											<tbody>
												{
													listaDatos !== null &&
													listaDatos.map((dato, index) => {
														return (
															<tr key={index}>
																{
																	dato.IdEquipo === datosPartido.IdLocal ?
																		<>
																			<td className='text-end local'>
																				<span>{dato.NombreJugador}</span>
																				<div
																					title={dato.NombreJugador}
																					className={`dato float-end mx-2 text-start ${dato.Dato === "G" ? ' gol ' : dato.Dato === "A" ? ' TA ' : ' TR '}`}>
																					{dato.Minuto}'
																				</div>
																			</td>
																			<td></td>
																		</>
																		:
																		<>
																			<td></td>
																			<td className='visitante'>
																				<div
																					title={dato.NombreJugador}
																					className={`dato mx-2 text-end ${dato.Dato === "G" ? ' gol ' : dato.Dato === "A" ? ' TA ' : ' TR '}`}>
																					{dato.Minuto}'
																				</div>
																				<span>{dato.NombreJugador}</span>
																			</td>
																		</>
																}
															</tr>
														)
													})
												}
											</tbody>
										</table>
									</div>
								</>
								:
								<div className="line"></div>
 
 */