import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



import { API_URL, URL_SVG, URL_UPLOAD } from '../utils/config'; // Importa la variable global
import { Menu } from './Menu';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { Loading } from './Loading';
import { Compartir } from './Compartir';



import { Footer } from './Footer';



export const Tabla = () => {



// console.log(API_URL);
// console.log(URL_SVG);
// console.log(URL_UPLOAD);



	const navigate = useNavigate();
	const [contadorLoading, setContadorLoading] = useState(0);



	const { idTorneo } = useParams();
	const [listaTabla, setListaTabla] = useState(null);
	const [listaEquipos, setListaEquipos] = useState([]);

	const sidebar = { actual: "tabla", idTorneo };
	const [listaZonas, setListaZonas] = useState([]);
	const [datosTorneo, setDatosTorneo] = useState(null);
	/**para controlar el renderizado */
	const [renderizar, setRenderizar] = useState(false);



	useEffect(() => {
		if (listaZonas.length > 0 && listaTabla !== null && datosTorneo !== null) {
			let tablaTemp = listaTabla;
			listaZonas.forEach(zona => {
				let puesto = 0;
				tablaTemp.forEach(tabla => {
					if (tabla.Zona === zona.Zona) {
						puesto++;
						tabla.Puesto = puesto;
					}
				});
			});
			setListaTabla(tablaTemp);
			setRenderizar(true);
		}
	}, [listaZonas, listaTabla, datosTorneo])



	useEffect(() => {
		//document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
		setContadorLoading(1);
		fetch(API_URL + 'Torneo/GetEquipos?IdTorneo=' + idTorneo)
			.then((response) => {
				switch (response.status) {
					case 200: response.json().then((listaEquipos) => {
						setListaEquipos(listaEquipos);
						setContadorLoading(1)
						fetch(API_URL + 'Torneo/GetTabla?IdTorneo=' + idTorneo)
							.then((response) => {
								switch (response.status) {
									case 200: response.json().then((r) => {
										if (r.torneo == null) {
											navigate('/');
											return;
										}
										if (r.torneo.Tipo === "ZO") {
											var zona = {};
											var listaZonasTemp = r.tabla.filter(function (e) {
												return zona[e.Zona] ? false : (zona[e.Zona] = true);
											});
											setListaZonas(listaZonasTemp);
										} else {
											setListaZonas([{ "Zona": "U" }]);
										}
										setDatosTorneo(r.torneo);
										setListaTabla(r.tabla);
									}); break;
									default: break;
								}
							})
							.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
							.finally(() => { setContadorLoading(-1) });
					}); break;
					default: break;
				}
			})
			.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
			.finally(() => { setContadorLoading(-1) });
	}, [idTorneo, navigate]);



	return (
		<>
			<Menu idTorneo={idTorneo} seccion="tabla" />
			<Header idTorneo={idTorneo} />
			<div className="container">
				<div className="row">
					<main className="col-md-9">
						<section className="tabla">
							<div className="panel-box my-5">
								<div className="titles">
									<h4>Tabla de Posiciones</h4>
								</div>
								<div className="tabla-list table-responsive">
									{
										renderizar === true
											?
											listaZonas.map((zona, indexZona) => {
												return (
													<div key={indexZona}>
														{
															zona.Zona !== "U" &&
															<div className="container">
																<h3>Zona {zona.Zona}</h3>
															</div>
														}
														<table className="table table-striped table-hover">
															<thead>
																<tr>
																	<th className="text-center">#</th>
																	<th className="text-left">Equipo</th>
																	<th className="text-center">P</th>
																	<th className="text-center">GF</th>
																	<th className="text-center">GC</th>
																</tr>
															</thead>
															<tbody>
																{

																	listaTabla.map((tabla, indexTabla) => {
																		const equipo = listaEquipos.filter(equipo => equipo.IdEquipo === tabla.IdEquipo)[0];
																		return (
																			tabla.Zona === zona.Zona &&
																			(
																				<tr
																					key={indexTabla}
																					onClick={() => { navigate('/equipo/' + idTorneo + '/' + tabla.IdEquipo) }}
																					className={`get-link ${+
																						(
																							(tabla.Puesto === 1 && datosTorneo.ContinuaEn === "FI")
																							|| (tabla.Puesto === 2 && datosTorneo.ContinuaEn === "SE")
																							|| (tabla.Puesto === 1 && tabla.Zona === "U")
																						) ? 'table-success' : '_' + indexTabla}`}>
																					<td className="text-center">{tabla.Puesto}</td>
																					<td className="text-left text-truncate">
																						<img src={equipo.ImagenEscudo === "" ? URL_SVG + 'escudo.svg' : URL_UPLOAD + equipo.ImagenEscudo} className="imgClass" alt="Equipo" />
																						<span>{equipo.Nombre}</span>
																					</td>
																					<td className="text-center">{tabla.Puntos}</td>
																					<td className="text-center">{tabla.GF}</td>
																					<td className="text-center">{tabla.GC}</td>
																				</tr>
																			)
																		)
																	})
																}
															</tbody>
														</table>
													</div>
												)
											})
											:
											(
												<table className="table-striped table-hover sk" key={10}>
													<tbody>
														<tr><td className='line' colSpan={5}></td></tr>
														<tr><td className='line' colSpan={5}></td></tr>
														<tr><td className='line' colSpan={5}></td></tr>
														<tr><td className='line' colSpan={5}></td></tr>
													</tbody>
												</table>
											)
									}
								</div>
							</div>
						</section>
					</main>
					<aside className="col-md-3">
						<Sidebar data={sidebar} />
						<Compartir seccion="tabla" idTorneo={idTorneo} />
					</aside>
				</div>
			</div>
			<Loading contador={contadorLoading} />
			<Footer />
		</>
	)
}
