import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';


import { Menu } from './Menu';
import { formatDate } from '../utils/FechaUtils';
import { Loading } from './Loading';
import { API_URL } from '../utils/config'; // Importa la variable global
import { Header } from './Header';
import { Footer } from './Footer';


export const Torneos = () => {


	const [contadorLoading, setContadorLoading] = useState(0);


	const { nombreUrl } = useParams();
//	const [campeonatoNombre, setCampeonatoNombre] = useState("");
	const [listaTorneos, setListaTorneos] = useState(null);


/*
	useEffect(() => {
		setContadorLoading(1);
	}, [nombreCampeonato]);
*/

	useEffect(() => {
		setContadorLoading(1);
		fetch(API_URL + 'Torneo/GetList?nombreCampeonato=' + nombreUrl)
			.then((response) => {
				switch (response.status) {
					case 200: response.json().then((torneosLista) => {
						setListaTorneos(torneosLista);
					}); break;
					default: break;
				}
			})
			.catch((error) => { })
			.finally(() => { setContadorLoading(-1) });
	}, [nombreUrl]);



	const navigate = useNavigate();
	const toTabla = (idTorneo) => {
		navigate('/tabla/' + idTorneo);
	};


	return (
		<>
			<Menu />
			<Header nombreUrl={nombreUrl} />
			<section className="torneos">
				<div className="container">
					<div className="row torneos-list">
						{
							listaTorneos !== null
								?
								listaTorneos.map(torneo => {
									return (
										<div className="col-md-6 col-lg-4 p-3" key={torneo.Id}>
											<article>
												<div className="panel-box">
													<div className="titles no-margin">
														<h4>{torneo.Nombre}</h4>
													</div>
													<div className="panel-body">
														<div className="row">
															<div className="col-6">Cancha de: </div>
															<div className="col-6 font-weight-bold">{torneo.Jugadores}</div>
														</div>
														<div className="row">
															<div className="col-6">Iniciado: </div>
															<div className="col-6 font-weight-bold">{formatDate(torneo.FechaInicio, 'dd/mm/yyyy')}</div>
														</div>
														<div className="row"><div className="col-6">Equipos: </div>
															<div className="col-6 font-weight-bold">{torneo.CantidadEquipos}</div>
														</div>
														<div className="row"><div className="col-6">Jugadores: </div>
															<div className="col-6 font-weight-bold">{torneo.CantidadJugadores}</div>
														</div>
														<div className="row">
															<button
																type="button"
																className="btn btn-primary btn-set-torneo"
																onClick={() => toTabla(torneo.Id)}>
																Ver
															</button>
														</div>
													</div>
												</div>
											</article>
										</div>
									);
								})
								:
								<>
									<div className="line col-md-6 col-lg-4 m-3"></div>
									<div className="line col-md-6 col-lg-4 m-3"></div>
									<div className="line col-md-6 col-lg-4 m-3"></div>
								</>
						}
					</div>
				</div>
			</section>
			<Loading contador={contadorLoading} />
			<Footer/>
		</>
	)
}
