import React, { useEffect, useState } from 'react'



import { ROOT_URL, URL_SVG } from '../utils/config';




//import ogImage from '../images/escudo.jpg';
export const Compartir = ({ seccion, idTorneo, idEquipo, idJugador, idPartido }) => {



	//	const params = props.data;
	//const [parametros, setParametros] = useState("");
	let urlTwitter = 'https://twitter.com/intent/tweet?text=';
	let urlWhatsapp = 'https://api.whatsapp.com/send?text=';
	const [urlX, setUrlX] = useState("");
	const [urlWA, setUrlWA] = useState("");



	useEffect(() => {
		if (seccion === 'jugador') {
			setUrlX(urlTwitter + ROOT_URL + 'jugador/' + idTorneo + '/' + idJugador + '/' + idEquipo);
			setUrlWA(urlWhatsapp + ROOT_URL + 'jugador/' + idTorneo + '/' + idJugador / '/' + idEquipo);
		}
		if (seccion === 'jugadores') {
			setUrlX(urlTwitter + ROOT_URL + 'jugadores/' + idTorneo + '/' + idEquipo);
			setUrlWA(urlWhatsapp + ROOT_URL + 'jugadores/' + idTorneo + '/' + idEquipo);
		}
		if (seccion === 'equipo') {
			setUrlX(urlTwitter + ROOT_URL + 'equipo/' + idTorneo + '/' + idEquipo);
			setUrlWA(urlWhatsapp + ROOT_URL + 'equipo/' + idTorneo + '/' + idEquipo);
		}
		if (seccion === 'tabla') {
			setUrlX(urlTwitter + ROOT_URL + 'tabla/' + idTorneo);
			setUrlWA(urlWhatsapp + ROOT_URL + 'tabla/' + idTorneo);
		}
		if (seccion === 'goleadores') {
			setUrlX(urlTwitter + ROOT_URL + 'goleadores/' + idTorneo);
			setUrlWA(urlWhatsapp + ROOT_URL + 'goleadors/' + idTorneo);
		}
		if (seccion === 'equipos') {
			setUrlX(urlTwitter + ROOT_URL + 'equipos/' + idTorneo);
			setUrlWA(urlWhatsapp + ROOT_URL + 'equipos/' + idTorneo);
		}
		if (seccion === 'fixture') {
			setUrlX(urlTwitter + ROOT_URL + 'fixture/' + idTorneo);
			setUrlWA(urlWhatsapp + ROOT_URL + 'fixture/' + idTorneo);
		}
		if (seccion === 'partido') {
			setUrlX(urlTwitter + ROOT_URL + 'partido/' + idPartido + '/' + idTorneo);
			setUrlWA(urlWhatsapp + ROOT_URL + 'partido/' + idPartido + '/' + idTorneo);
		}
	}, [idTorneo, seccion, idEquipo, urlTwitter, urlWhatsapp, idJugador, idPartido]);



	return (
		<>
			<section className="share my-5">
				<div className="row text-center">
					<h4>Compartir</h4>
				</div>
				<div className="d-flex justify-content-center">
					<a href={urlWA}
						target="_blank"
						rel="noopener noreferrer"
						className="share-wa">
						<img
							src={URL_SVG +  "ico-whatsapp.svg"}
							alt="Compartir en Whatsapp"
							className="img img-share" />
					</a>
					<a href={urlX}
						target="_blank"
						rel="noopener noreferrer"
						className="share-tw">
						<img
							src={URL_SVG + "ico-x.svg"}
							alt="Compartir en Twitter"
							className="img img-share" />
					</a>
				</div>
			</section>
		</>
	)
}
