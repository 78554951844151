import React, { useEffect, useState } from 'react'


export const Loading = ({ contador }) => {


	const [contadorLoading, setContadorLoading] = useState(0);


	useEffect(() => {
		// Utiliza la función de actualización del estado para garantizar que estás obteniendo el valor más reciente
		setContadorLoading(prevContadorLoading => prevContadorLoading + contador);
	  }, [contador]);
	


	return (
		<>
			{
				contadorLoading > 0 &&
				<div className="spinner">
					<div className="half-spinner"></div>
				</div>
			}
		</>
	)
}
