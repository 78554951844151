import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



import { Loading } from './Loading';
import { Menu } from './Menu';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { API_URL, URL_SVG } from '../utils/config';
import { Compartir } from './Compartir';



import { Footer } from './Footer';



export const Equipos = () => {



	const navigate = useNavigate();
	const [contadorLoading, setContadorLoading] = useState(0);



	// Global
	const { idTorneo } = useParams();
	//	const [dataHeader, setDataHeader] = useState({nombreCampeonato:"", nombreTorneo:""});
	let sidebar = { actual: "equipos", idTorneo };
	//	const { config } = useMiContexto();


	const [listaEquipos, setListaEquipos] = useState([]);



	/*
		useEffect(() => {
			setContadorLoading(1);
			fetch(API_URL + 'Torneo/GetData?idTorneo=' + idTorneo)
				.then((response) => {
					switch (response.status) {
						case 200: response.json().then((r) => {
							setDataHeader(r);
						}); break;
						default: break;
					}
				})
				.catch((error) => { })
				.finally(() => { setContadorLoading(-1) });
		}, [idTorneo]);
	*/


	useEffect(() => {
		/*
		document.querySelector('meta[property="og:title"]').setAttribute('content', 'Listado de Equipos');
		document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
		*/
		if (listaEquipos.length === 0) {
			setContadorLoading(1);
			fetch(API_URL + 'Torneo/GetEquipos?IdTorneo=' + idTorneo)
				.then((response) => {
					switch (response.status) {
						case 200: response.json().then((equipos) => {
							if (equipos.length === 0) {
								navigate('/');
								return;
							}
							setListaEquipos(equipos);
						}); break;
						default: break;
					}
				})
				.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
				.finally(() => { setContadorLoading(-1) });
		}
	}, [idTorneo, listaEquipos, navigate]);



	return (
		<>
			<Menu idTorneo={idTorneo} seccion="equipos" />
			<Header idTorneo={idTorneo} />
			<div className="container">
				<div className="row">
					<main className="col-md-9">
						<section className="equipos">
							<div className="panel-box panel-box-grey my-5">
								<div className="titles panel-box p-0">
									<h4>Listado de Equipos</h4>
								</div>
								<div className="row equipos-list">
									{listaEquipos.map((equipo, index) => {
										return (
											<div className="col-md-6 col-lg-4" key={index}>
												<div className="panel-box" data-idequipo={equipo.IdEquipo}>
													<div className="titles">
														<h4>{equipo.Nombre}</h4>
													</div>
													<div className="item px-2">
														<div className="item-head text-center">
															<img
																src={equipo.ImagenPerfil === "" ? URL_SVG + "equipo.svg" : equipo.ImagenPerfil}
																alt={equipo.Nombre}
																className="imgClassImagen" />
														</div>
														<div className="item-info">
															<span className="item-logo">
																<img
																	src={equipo.ImagenEscudo === "" ? URL_SVG + "escudo.svg" : equipo.ImagenEscudo}
																	alt={equipo.Nombre}
																	className="imgClassEscudo" />
															</span>
														</div>
														<div className="item-footer">
															<div className="row">
																<button
																	type="button"
																	className="btn btn-primary btn-get-equipo my-1"
																	onClick={() => { navigate('/equipo/' + idTorneo + '/' + equipo.IdEquipo) }}
																	data-idequipo={equipo.IdEquipo}>
																	VER
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										)
									})}
								</div>
							</div>
						</section>
					</main>
					<aside className="col-md-3">
						<Sidebar data={sidebar} />
						<Compartir seccion="equipos" idTorneo={idTorneo} />
					</aside>
				</div>
			</div>
			<Loading contador={contadorLoading} />
			<Footer />
		</>
	)
}
