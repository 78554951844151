import React, { useState, useEffect } from 'react'



import { Loading } from './Loading';
import { Menu } from './Menu';
import { Header } from './Header';
import { API_URL } from '../utils/config';
import { Footer } from './Footer';



export const Registro = () => {



	const [contadorLoading, setContadorLoading] = useState(0);



	const [listaPaises, setListaPaises] = useState([]);
	const [listaCiudades, setListaCiudades] = useState([]);
	const [nombre, setNombre] = useState("");
	const [nombreUrl, setNombreUrl] = useState("");
	const [idPais, setIdPais] = useState(0);
	const [idCiudad, setIdCiudad] = useState(0);
	const [nombreadmin, setNombreAdmin] = useState("");
	const [mail, setMail] = useState("");
	const [domicilio, setDomicilio] = useState("");
	const [telefono, setTelefono] = useState("");
	const [nombreUrlValido, setNombreUrlValido] = useState(true);
	const [codigoRespuestaRegistro, setCodigoRespuestaRegistro] = useState(0);
	const [mensajeRegistro, setMensajeRegistro] = useState("");



	useEffect(() => {
		if (idPais > 0) {
			setContadorLoading(1);
			fetch(API_URL + 'Select/Ciudades?idPais=' + idPais)
				.then((response) => {
					switch (response.status) {
						case 200: response.json().then((lista) => {
							setListaCiudades(lista);
							setIdCiudad(lista[0].Id);
						}); break;
						default: break;
					}
				})
				.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
				.finally(() => { setContadorLoading(-1); });
		}
	}, [idPais])


	useEffect(() => {
		setContadorLoading(1);
		fetch(API_URL + 'Select/Paises')
			.then((response) => {
				switch (response.status) {
					case 200: response.json().then((lista) => {
						setListaPaises(lista);
						setIdPais(lista[0].Id);
					}); break;
					default: break;
				}
			})
			.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
			.finally(() => { setContadorLoading(-1); });
	}, [])



	const [texto, setTexto] = useState('');
	useEffect(() => {
		const handleConsultaServidor = () => {
			if (texto !== "") {
				setContadorLoading(1);
				fetch(API_URL + 'Campeonato/GetURL?nombre=' + texto)
					.then((response) => {
						switch (response.status) {
							case 200: {
								response.json().then((r) => {
									setNombreUrl(r.nombreUrl);
								});
								setNombreUrlValido(true);
								break;
							}
							case 400: {
								console.log("ese nombre ya está en uso");
								setNombreUrl("El nombre está en uso.");
								setNombreUrlValido(false);
								break;
							}
							default: break;
						}
					})
					.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
					.finally(() => { setContadorLoading(-1); });
			}
		};
		const timeoutId = setTimeout(handleConsultaServidor, 1000);
		return () => clearTimeout(timeoutId);
	}, [texto]);

	const handleChangeTexto = (e) => {
		setTexto(e.target.value);
		setNombre(e.target.value);
	};



	const post = () => {

		if ((nombre === "") || (nombreadmin === "") || (mail === "") || (nombreUrl === "")) {
			setMensajeRegistro("Faltan completar datos de los campos para crear el campeonato.");
			setCodigoRespuestaRegistro(400);
			return;
		}

		setContadorLoading(1);
		var data = new FormData();
		data.append("Nombre", nombre);
		data.append("NombreUrl", nombreUrl);
		data.append("IdCiudad", idCiudad);
		data.append("NombreAdmin", nombreadmin);
		data.append("Mail", mail);
		data.append("Domicilio", domicilio);
		data.append("Telefono", telefono);
		let HEADER_POST = { 'method': 'POST', 'body': null, 'credentials': 'include', 'withCredentials': true };
		HEADER_POST.body = data;
		fetch(API_URL + 'Campeonato/Agregar', HEADER_POST)
			.then((response) => {
				switch (response.status) {
					case 200:
						response.json().then((r) => {
							setMensajeRegistro(r.msg);
							setCodigoRespuestaRegistro(r.code);
							if (r.code === 200) {
								setTimeout(() => {
									window.location.href = "/";
								}, 10000);
							}
						});
						break;
					default: break;
				}
			})
			.catch((error) => { })
			.finally(() => { setContadorLoading(-1); });
	};



	return (
		<>
			<Menu seccion="registro" />
			<Header titulo="Registrar nuevo campeonato" />
			<div className="container">
				<div className="row">
					<main className="col-md-9">
						<section className="registro">
							<div className="panel-box panel-box-grey my-5">
								<div className="titles panel-box p-0">
									<h4>Ingreso de datos</h4>
								</div>
								<div className="panel-body">
									<div className={`alert ${nombreUrlValido ? ' alert-success ' : ' alert-danger '} ${nombreUrl === "" ? ' invisible ' : ''}`}>
										{API_URL + nombreUrl}
									</div>
									<form className="form-theme" action="" id="formRegistro">
										<div className="row">
											<div className="col-md-6">
												<div className="row justify-content-md-center">
													<h4>Datos del Campeonato</h4>
													<div className="form-group">
														<label htmlFor='NombreUrl'>Nombre del campeonato *</label>
														<input
															value={nombre}
															onChange={handleChangeTexto}
															type="text"
															id="Nombre"
															className="form-control"
															placeholder="Nombre del campeonato"
															required="required" />
														<input
															value={nombreUrl}
															type="hidden" />
													</div>
													<div className="form-group">
														<label htmlFor='IdPais'>País *</label>
														<select
															value={idPais}
															onChange={(e) => setIdPais(e.target.value)}
															id="IdPais"
															name="IdPais"
															className="form-select">
															{
																listaPaises.length > 0 &&
																listaPaises.map((pais, index) => {
																	return (
																		<option key={index} value={pais.Id}>{pais.Label}</option>
																	)
																})
															}
														</select>
													</div>
													<div className="form-group">
														<label htmlFor='IdCiudad'> Ciudad </label>
														<select
															value={idCiudad}
															onChange={(e) => setIdCiudad(e.target.value)}
															id="IdCiudad"
															className="form-select">
															{
																listaCiudades.length > 0 &&
																listaCiudades.map((ciudad, index) => {
																	return (
																		<option key={index} value={ciudad.Id}>{ciudad.Label}</option>
																	)
																})
															}
														</select>
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="row justify-content-md-center">
													<h4>Datos del Administrador</h4>
													<div className="form-group">
														<label htmlFor='nombreadmin'>Nombre del Admin *</label>
														<input
															value={nombreadmin}
															onChange={(e) => setNombreAdmin(e.target.value)}
															type="text"
															required="required"
															className="form-control"
															id="nombreadmin"
															placeholder="Escriba su nombre" />
													</div>
													<div className="form-group">
														<label htmlFor='Mail'>Mail *</label>
														<input
															value={mail}
															onChange={(e) => setMail(e.target.value)}
															type="email"
															required="required"
															className="form-control"
															id="Mail"
															pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}" />
													</div>
													<div className="form-group">
														<label htmlFor='Domicilio'>Domicilio</label>
														<input
															value={domicilio}
															onChange={(e) => setDomicilio(e.target.value)}
															type="text"
															className="form-control"
															id="Domicilio" />
													</div>
													<div className="form-group">
														<label htmlFor='Telefono'> Tel / Celular </label>
														<input
															value={telefono}
															onChange={(e) => setTelefono(e.target.value)}
															type="number"
															id="Telefono"
															className="form-control" />
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-12">
													<div className={`alert ${codigoRespuestaRegistro === 200 ? ' alert-success d-block ' : (codigoRespuestaRegistro === 400) ? ' alert-danger d-block ' : ' d-none '} `}>
														{mensajeRegistro}
													</div>
													<button
														onClick={post}
														type="button"
														value="Confirmar"
														className="btn btn-lg btn-primary btn-registro float-end">
														Confirmar
													</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</section>
					</main>
					<aside className='col-md-3'>
						<div className="panel-box panel-box-grey my-5">
							<div className="titles panel-box p-0"><h4>Nota</h4></div>
							<div className="panel-body">
								<span className="h6 container">
									El nombre que elija para su campeonato será el que contenga todos los torneos que luego se vayan organizando,
									podrá compartir directamente el link para que cualquiera pueda acceder a los datos de los torneos que se estan disputando
									o ya se hayan disputado.
								</span>
							</div>
						</div>
					</aside>
				</div>
			</div>
			<Loading contador={contadorLoading} />
			<Footer/>
		</>
	)
}
