import React from 'react'
import { useNavigate } from 'react-router-dom';


export const Sidebar = (props) => {


	const params = props.data;
	const navigate = useNavigate();


	return (
		<>
			<div className="panel-box my-5 datos">
				<div className="titles">
					<h4>Buscar</h4>
				</div>
				<div className="panel-body">
					{(params.actual !== "tabla") &&
						<div className="row buscador-tabla">
							<button
								className="btn btn-primary btn-get-tabla"
								onClick={() => { navigate('/tabla/' + params.idTorneo) }}
								type="button">Posiciones</button>
						</div>
					}
					{(params.actual !== "fixture") &&
						<div className="row buscador-fixture">
							<button
								className="btn btn-primary btn-get-fixture"
								onClick={() => { navigate('/fixture/' + params.idTorneo) }}
								type="button">Fixture</button>
						</div>
					}
					{(params.actual !== "goleadores") &&
						<div className="row buscador-goleadores">
							<button
								className="btn btn-primary btn-get-goleadores"
								onClick={() => { navigate('/goleadores/' + params.idTorneo) }}
								type="button">Goleadores</button>
						</div>
					}
					{(params.actual !== "equipos") &&
						<div className="row buscador-equipos">
							<button
								className="btn btn-primary btn-get-equipos"
								onClick={() => { navigate('/equipos/' + params.idTorneo) }}
								type="button">Equipos</button>
						</div>
					}
					{(params.actual === "equipo") &&
						<div className="row buscador-equipos">
							<button
								className="btn btn-primary btn-get-equipos"
								onClick={() => { navigate('/jugadores/' + params.idTorneo + '/' + params.idEquipo) }}
								type="button">Jugadores</button>
						</div>
					}
					{(params.actual === "jugador") &&
						<div className="row buscador-equipos">
							<button
								className="btn btn-primary btn-get-equipos"
								onClick={() => { navigate('/jugadores/' + params.idTorneo + '/' + params.idEquipo) }}
								type="button">Jugadores</button>
						</div>
					}
				</div>
			</div >
		</>
	)
}
