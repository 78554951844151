import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'




import { URL_SVG } from '../utils/config';




export const Menu = ({ idTorneo, seccion }) => {




	const [rutaTabla, setRutaTabla] = useState("");
	const [rutaFixture, setRutaFixture] = useState("");
	const [rutaGoleadores, setRutaGoleadores] = useState("");
	const [rutaEquipos, setRutaEquipos] = useState("");
	const campeonatoNombre = useRef(null);




	useEffect(() => {
		if (typeof idTorneo !== 'undefined') {
			setRutaTabla('/tabla/' + idTorneo);
			setRutaFixture('/fixture/' + idTorneo);
			setRutaGoleadores('/goleadores/' + idTorneo);
			setRutaEquipos('/equipos/' + idTorneo);
			campeonatoNombre.current = localStorage.getItem('campeonatoNombre');
		}
	}, [setRutaTabla, idTorneo]);




	return (
		<>
			<nav className="navbar navbar-expand-lg navbar-light mainmenu sticky-top">
				<div className="container">
					<Link className="navbar-brand" to="/home">
						<img src={URL_SVG + 'logocampeonato.svg'} alt="Logo" width="30" className="d-inline-block align-text-top" />
					</Link>
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="sf-menu navbar-nav me-auto mb-lg-0">
							<li><Link className="tohome" to="/">Home</Link></li>
							{(typeof idTorneo !== 'undefined') &&
								<li className="current menu-torneo">
									<Link
										className="sf-with-ul menu-link"
										data-section="Tabla"
										to={`/torneos/${campeonatoNombre.current ? campeonatoNombre.current : ''}`}
									>Torneo</Link>
									<ul className="sub-current">
										<li>
											{
												seccion !== "tabla" &&
												<Link
													to={rutaTabla}
													className="menu-link"
													data-section="Tabla">Tabla de Posiciones</Link>
											}
										</li>
										<li>
											{
												seccion !== "fixture" &&
												<Link
													to={rutaFixture}
													className="menu-link"
													data-section="Fixture">Fixture</Link>
											}
										</li>
										<li>
											{
												seccion !== "goleadores" &&
												<Link
													to={rutaGoleadores}
													className="menu-link"
													data-section="Goleadores">Goleadores</Link>
											}
										</li>
										<li>
											{
												seccion !== "equipos" &&
												<Link
													to={rutaEquipos}
													className="menu-link"
													data-section="Equipos">Equipos</Link>
											}
										</li>
									</ul>
								</li>
							}
							<li><Link to="/buscador" className="menu-link">Buscar Campeonato</Link></li>
							<li><a href="/admin" className="menu-link">Soy Organizador</a></li>
							<li><Link to="/registro" className="menu-link">Registro</Link></li>
							<li><Link to="/comoFunciona" className="menu-link">Como Funciona</Link></li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	)
}
