import React, { useState } from 'react'



import { HEADER_POST, URL_API_ADMIN } from '../../utils/config';



export const ModalContactanos = ({ show, onShow }) => {



	const [estado, setEstado] = useState('mensaje');
	const [texto, setTexto] = useState("");
	const handleClose = () => {
		onShow(false)
	};




	const post = () => {
		if (texto === "") {
			setEstado('sintexto');
			setTimeout(() => {
				setEstado('mensaje')
			}, 3000);
		} else {
			var data = new FormData();
			data.append("Texto", texto);
			HEADER_POST.body = data;
			fetch(URL_API_ADMIN + "Contactanos", HEADER_POST)
				.then((response) => {
					switch (response.status) {
						case 200: response.json().then((r) => {
							if (r.code === 200) {
								setEstado('gracias');
								setTimeout(() => {
									onShow(false);
								}, 5000);
							}
						}); break;
						case 401: break;
						default: break;
					}
				})
				.catch((error) => { })
				.finally(() => { });
		}
	};



	return (
		<>
			<div
				className={`modal fade ${show === true ? 'show' : ''}`}
				style={{ display: show === true ? 'block' : 'none' }}>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Mensaje</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
						</div>
						<div className='modal-body'>
							{
								estado === 'mensaje' &&
								<textarea
									onChange={(e) => setTexto(e.target.value)}
									value={texto}
									className='form-control'
									placeholder='Dejanos un mensaje y un medio de respuesta como un teléfono o email.'>
								</textarea>
							}
							{
								estado === 'gracias' &&
								<div className="alert alert-success">
									<h5>Gracias por comunicarte, estamos en contacto.</h5>
								</div>
							}
							{
								estado === 'sintexto' &&
								<div className="alert alert-warning">
									<h5>Debes ingresar un texto.</h5>
								</div>
							}

						</div>
						<div className="modal-footer">
							{
								estado === 'mensaje' &&

								<button
									type="button"
									onClick={post}
									className="btn btn-primary btn-confirmar btn-sm">Confirmar</button>
							}
						</div>
					</div>
				</div>
			</div>
			{
				show &&
				<div className="modal-backdrop fade show" onClick={handleClose}></div>
			}
		</>
	)
}