// import { format } from 'date-fns';

export const formatDate = (date, formatString) => {


	const arrayFecha =  formatString.split("/");


	if ( typeof date === "string" ) {
		let fecha = new Date(date);
		let fechaFormateada = '';
		if (arrayFecha[0]==='dd') {
			fechaFormateada += ('0' + fecha.getUTCDate()).substring( ('0' + fecha.getUTCDate()).length -2) + '/';
		}
		if (arrayFecha[1]==='mm') {
			fechaFormateada += ('0' + (fecha.getUTCMonth() + 1)).substring( ('0' + fecha.getUTCMonth()).length -5) + '/';
		}
		if (arrayFecha[2]==='yyyy') {
			fechaFormateada += fecha.getUTCFullYear();
		}
		return fechaFormateada;
	}
//	return format(date, formatString);
}