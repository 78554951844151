import React, { createContext, useState } from "react";
export const Context = createContext({});

export default function ContextProvider({ children }) {

    const [datos, setDatos] = useState({})

    return (
        <Context.Provider value={{datos, setDatos}}>
            {children}
        </Context.Provider>
    )
}