import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



import { Loading } from './Loading';
import { Menu } from './Menu';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { API_URL } from '../utils/config';
import { Compartir } from './Compartir';
import { Footer } from './Footer';



export const Goleadores = () => {



	const navigate = useNavigate();



	// Global
	const [contadorLoading, setContadorLoading] = useState(0);


//	const [dataHeader, setDataHeader] = useState({nombreCampeonato:"", nombreTorneo:""});
	const [listaGoleadores, setListaGoleadores] = useState(null);
	const { idTorneo } = useParams();
	let sidebar = { actual: "goleadores", idTorneo };


/*
	useEffect(() => {
		setContadorLoading(1);
		fetch(API_URL + 'Torneo/GetData?idTorneo=' + idTorneo)
			.then((response) => {
				switch (response.status) {
					case 200: response.json().then((r) => {
						setDataHeader(r);
					}); break;
					default: break;
				}
			})
			.catch((error) => { })
			.finally(() => { setContadorLoading(-1) });
	}, [idTorneo]);
*/


	useEffect(() => {
		//document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
		setContadorLoading(1);
		fetch(API_URL + 'Torneo/GetTablaGoleadores?IdTorneo=' + idTorneo)
			.then((response) => {
				switch (response.status) {
					case 200: response.json().then((goleadores) => {
						setListaGoleadores(goleadores)
					}); break;
					default: break;
				}
			})
			.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
			.finally(() => { setContadorLoading(-1) });
	}, [idTorneo]);



	return (
		<>
			<Menu idTorneo={idTorneo} seccion="goleadores" />
			<Header idTorneo={idTorneo}/>
			<div className="container">
				<div className="row">
					<main className="col-md-9">
						<section className="goleadores">
							<div className="panel-box my-5">
								<div className="titles">
									<h4>Tabla de Goleadores</h4>
									<table className="table-striped table-hover sk">
										<thead>
											<tr>
												<th className="text-center">#</th>
												<th className="text-center">Goles</th>
												<th className="text-left">Jugador</th>
												<th className="text-left">Equipo</th>
											</tr>
										</thead>
										<tbody>
											{
												listaGoleadores !== null
													?
													listaGoleadores.map((goleador, index) => {
														return (
															<tr className="getJugador get-link" data-idjugador={goleador.IdJugador} key={index}>
																<td className="text-center">{index + 1}</td>
																<td className="text-center">{goleador.Dato}</td>
																<td className="text-left">{goleador.NombreJugador}</td>
																<td
																	onClick={() => { navigate('/equipo/' + idTorneo + '/' + goleador.IdEquipo) }}
																	className="text-left">{goleador.NombreEquipo}</td>
															</tr>
														)
													})
													:
													<>
														<tr ><td className='line' colSpan={4} ></td></tr>
														<tr ><td className='line' colSpan={4}></td></tr>
														<tr ><td className='line' colSpan={4}></td></tr>
														<tr ><td className='line' colSpan={4}></td></tr>
													</>
											}
										</tbody>
									</table>
								</div>
							</div>
						</section>
					</main>
					<aside className="col-md-3">
						<Sidebar data={sidebar} />
						<Compartir seccion="goleadores" idTorneo={idTorneo} />
					</aside>
				</div>
			</div>
			<Loading contador={contadorLoading} />
			<Footer/>
		</>
	)
}
