const urlApi = process.env.REACT_APP_API_URL;
const urlUpload = process.env.REACT_APP_UPLOAD;
// console.log("env");
// console.log(process.env.REACT_APP_UPLOAD);
//console.log(process.env.REACT_APP_API);
//console.log(process.env.REACT_APP_BASE_URL);
//console.log(urlApi);


//export const API_URL = 'https://10liga.com/api/';
export const API_URL = urlApi;
export const ROOT_URL = 'https://10liga.com/site/';
export const JS_EQUIPOS = [];
export const URL_IMAGES = 'https://10liga.com/Uploads/';
export const URL_SVG = 'https://10liga.com/site/assets/images/svg/';
export const URL_UPLOAD = urlUpload;
export const URL_INSTAGRAM = 'https://instagram.com/';


/** Se habilitó para los mensajes de contactanos */
export const HEADER_POST =  { 'method': 'POST', 'body': null, 'credentials': 'include', 'withCredentials': true};
export const URL_API_ADMIN = 'https://10liga.com/api/Admin/';