import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';



import { Loading } from './Loading';
import { Menu } from './Menu';
import { Header } from './Header';
import { API_URL, URL_IMAGES, URL_SVG } from '../utils/config';
import { Sidebar } from './Sidebar';
import { Compartir } from './Compartir';
import { Footer } from './Footer';



export const Jugador = () => {


	// Global
	const [contadorLoading, setContadorLoading] = useState(0);
	const { idTorneo } = useParams();
	const { idJugador } = useParams();
	const { idEquipo } = useParams();
	let sidebar = { actual: "jugador", idTorneo, idEquipo };


	const [jugador, setJugador] = useState({
		"NombreJugador": "",
		"Peso": "",
		"Altura": "",
		"Puesto": "",
		"Numero": "",
		"ImagenPerfil": "",
		"G": "",
		"A": "",
		"R": "",
		"Datos": null
	});



	useEffect(() => {
		setContadorLoading(1)
		fetch(API_URL + 'Torneo/GetJugador?IdJugador=' + idJugador + '&IdTorneo=' + idTorneo)
		.then((response) => {
			switch (response.status) {
				case 200: response.json().then((jugador) => {
					setJugador(jugador.datos)
					//document.querySelector('meta[property="og:title"]').setAttribute('content', jugador.datos.NombreJugador);
				}); break;
				default: break;
			}
		})
		.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
		.finally(() => { setContadorLoading(-1);});
	}, [idJugador, idTorneo]);


	
	return (
		<>
			<Menu idTorneo={idTorneo} seccion="jugador" />
			<Header idTorneo={idTorneo} />
			<div className="container">
				<div className="row">
					<main className="col-md-9">
						<section className="jugador">
							<div className="panel-box my-5">
								<div className="titles">
									<h4 className="jugador-title">
										{jugador.NombreJugador}
									</h4>
								</div>
								<div className="jugador-data">
									<div className="col p-3 position-relative">

										<img
											className={jugador.ImagenPerfil === "" ? 'img img-50 w-100' : 'img w-100'}
											src={jugador.ImagenPerfil === "" ? URL_SVG + "j0.svg" : URL_IMAGES + 'Jugador/' + jugador.ImagenPerfil}
											alt={jugador.Nombre} />

										<div className="panel-box datos-jugador opacity-75 position-absolute top-0 start-0 m-3">
											<div className="panel-body">
												<div className="row row-dashed">
													<div className="col-3 ico ico-pelota"></div>
													<div className="col-9 font-weight-bold jugador-goles text-start text-nowrap">{jugador.G}</div>
												</div>
												<div className="row row-dashed">
													<div className="col-3 ico ico-numero"></div>
													<div className="col-9 font-weight-bold jugador-numero text-start text-nowrap">{jugador.Numero}</div>
												</div>
												<div className="row row-dashed">
													<div className="col-3 ico ico-puesto"></div>
													<div className="col-9 font-weight-bold jugador-puesto text-start text-truncate">{jugador.Puesto}</div>
												</div>
												<div className="row row-dashed">
													<div className="col-3 ico ico-TA"></div>
													<div className="col-9 font-weight-bold jugador-TA text-start text-nowrap">{jugador.A}</div>
												</div>
												<div className="row row-dashed">
													<div className="col-3 ico ico-TR"></div>
													<div className="col-9 font-weight-bold jugador-TR text-start text-nowrap">{jugador.R}</div>
												</div>
												<div className="row row-dashed">
													<div className="col-3 ico ico-altura"></div>
													<div className="col-9 font-weight-bold jugador-altura text-start text-nowrap">{jugador.Altura} cms.</div>
												</div>
												<div className="row row-dashed">
													<div className="col-3 ico ico-peso"></div>
													<div className="col-9 font-weight-bold jugador-peso text-start text-nowrap">{jugador.Peso} kgs.</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="jugador-gallery"></div>
							</div>
						</section>
					</main>
					<aside className="col-md-3">
						<Sidebar data={sidebar} />
						<Compartir seccion="jugador" idJugador={idJugador} idEquipo={idEquipo} idTorneo={idTorneo} />
					</aside>
				</div>
			</div>
			<Loading contador={contadorLoading} />
			<Footer/>
		</>
	)
}
