import React, { useEffect, useState } from 'react'
import { Menu } from './Menu'
//import { Fade } from 'react-slideshow-image';
//import 'react-slideshow-image/dist/styles.css';
//import '../assets/css/slide.css';
import { useNavigate } from 'react-router-dom';



import { API_URL } from '../utils/config';
//import { Footer } from './Footer';
import { Background } from '../utils/Background/Background';



export const Home = () => {



	const navigate = useNavigate();



	const [imagenesBack, setImagenesBack] = useState(null);



	//	useEffect(() => {
	//		fetch( '/')
	//			.then((response) => {
	//				switch (response.status) {
	//					case 200: response.json().then((listaImagenes) => {
	//						console.log(listaImagenes);
	//					}); break;
	//					default: break;
	//				}
	//			})
	//			.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
	//			.finally(() => { });
	//	}, []);



	//console.log(API_URL);
	useEffect(() => {
		fetch(API_URL + 'Campeonato/Background')
			.then((response) => {
				switch (response.status) {
					case 200: response.json().then((listaImagenes) => {
						setImagenesBack(listaImagenes.sort(() => .5 - Math.random()));
					}); break;
					default: break;
				}
			})
			.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
			.finally(() => { });
	}, []);



	return (
		<>
			<Menu />
			{
				imagenesBack !== null &&
				<Background imagenesBack={imagenesBack} />
			}
			<div className="home">
				<h2>El sitio para administrar tu campeonato</h2>
				<h1> . . y es GRATIS</h1>
				<div className='botonesHome'>
					<button
						onClick={() => { navigate('/buscador') }}
						className="btn btn-primary tobuscador">
						Buscar Campeonato
					</button>
					<button
						className="btn btn-info custom-button">
						Como funciona
					</button>
				</div>
			</div>
		</>
	)
}
/**
 * 			<Fade arrows={false}>
				{
					imagenesBack &&
					imagenesBack.map((each, index) => (
						<div key={index} className="each-slide">
							<div>
								<img style={{ objectFit: "cover" }} alt="Futbol" src={each} />
							</div>
						</div>
					))
				}
			</Fade>

 */